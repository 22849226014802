<template>
  <div :class="$style.groups">
    <div :class="$style.wrapper">
      <div :class="$style.search">
        <Autocomplete
          :search="search"
          placeholder="Поиск"
          :valueNames="['name']"
          @querySearch="querySearch"
          @selectItem="handleSelectGroup"
          @handleFindItems="handleFindGroups"
          @input="search = $event"
        />
        <el-button
          :class="$style.button"
          style="margin-left: 0; margin-bottom: 0; border-radius: 0"
          type="primary"
          @click="getList"
        >
          Показать
        </el-button>
      </div>
      <el-button
        :class="$style.button"
        type="primary"
        @click="
          $router.push($options.ADDWINE_ADMIN_ROUTES.CATALOG.GROUPS.CREATE)
        "
      >
        Создать
      </el-button>
    </div>
    <el-table :data="groups" stripe>
      <el-table-column prop="orderField" label="Приоритет"></el-table-column>
      <el-table-column prop="name" label="Название"></el-table-column>
      <el-table-column prop="slug" label="URL"></el-table-column>
      <el-table-column label="Отображать в блоке специальные" width="350"
        ><template slot-scope="scope"
          ><Checkbox :checked="scope.row.isSpecial" :class="$style.checkBox"
        /></template>
      </el-table-column>
      <el-table-column width="80">
        <template slot-scope="scope">
          <ActionButtons
            :edit-link="
              getRoute({
                route: $options.ADDWINE_ADMIN_ROUTES.CATALOG.GROUPS.GROUP,
                params: { id: scope.row.id },
              })
            "
            @delete="remove(scope.row.id)"
          />
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="getList"
      background
    >
    </el-pagination>
  </div>
</template>

<script>
import delivery from '@/delivery'

import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import Autocomplete from '@/components/atoms/Autocomplete.vue'
import Checkbox from '@/components/atoms/Checkbox.vue'

import { ADDWINE_ADMIN_ROUTES, getRoute } from '@/constants/routing'

export default {
  components: { ActionButtons, Autocomplete, Checkbox },
  ADDWINE_ADMIN_ROUTES,
  data() {
    return {
      groups: [],
      isOpenModal: false,
      total: 0,
      page: 1,
      limit: 20,
      search: '',
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const loading = this.$loading({
        lock: true,
      })

      const query = {
        limit: this.limit,
        page: this.page,
        orderBy: 'order_field',
        orderDir: 'desc',
      }

      if (this.search) {
        query.search = this.search
      }

      const { value, error } =
        await delivery.ProductsCore.GroupsActions.getList(query)

      loading.close()

      if (error) return

      this.groups = value.data
      this.total = value.meta.count
    },
    async remove(id) {
      const res = await delivery.ProductsCore.GroupsActions.delete(id)
      if (!res.error) {
        this.getList()
      }
    },
    handleSelectGroup(selectedGroup) {
      this.$router.push(
        this.getRoute({
          route: ADDWINE_ADMIN_ROUTES.CATALOG.GROUPS.GROUP,
          params: { id: selectedGroup.id },
        }),
      )
    },
    async handleFindGroups() {
      await this.getList()
    },
    async querySearch({ queryString, setSearchItems }) {
      const { value, error } =
        await delivery.ProductsCore.GroupsActions.getList({
          limit: this.limit,
          page: this.page,
          search: queryString,
        })

      if (error) return

      setSearchItems(value.data)
    },
    getRoute({ route, site, params }) {
      return getRoute({ route, site, params })
    },
  },
}
</script>

<style lang="scss" module>
.groups {
  padding: 1rem;
  .wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1rem;
    .search {
      margin-right: auto;
      display: flex;
      align-items: center;
      input {
        border-radius: 0;
      }
    }
  }
  .modal {
    label {
      margin: 0;
    }
  }
  .pagination {
    text-align: center;
  }

  .checkBox {
    display: flex;
    justify-content: center;
    margin-right: 6rem;
  }
}
</style>
